<template>
  <div class="ai-shelf-detection-container root text-center">
    <div
      class="d-flex banner-section mx-4 pt-5 pb-3 position-relative justify-content-center"
    >
      <img id="banner-img" :src="bannerImg" width="100%" height="330px" />
      <button
        id="join-event"
        class="position-absolute btn btn-primary"
        @click="gotoUpload"
      >
        <h5 class="mb-0">ร่วมสนุก คลิกที่นี่</h5>
      </button>
    </div>
    <div class="announce-section mx-4 mt-5 mb-3">
      <button class="btn btn-info w-100" @click="gotoAnnounce">
        <div class="row">
          <div class="col">
            <div class="image" />
          </div>
          <div class="col align-self-center">
            <h5 class="text-left mb-0">ตรวจสอบคะแนน</h5>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
// import BoxImage from '@/assets/box_ai.png'
import BoxImage from '@/assets/Banner_AIShelfv2.png'

export default {
  name: 'ai-shelf',
  data() {
    return {
      bannerImg: BoxImage,
    }
  },
  methods: {
    gotoUpload() {
      this.$router.push({ name: 'ai-shelf-upload' })
    },
    gotoAnnounce() {
      this.$router.push({ name: 'ai-shelf-history' })
    },
  },
  mounted() {
    window.loading(false)
  },
}
</script>

<style lang="scss" scoped>
$light-blue: #32c3be;
$button-blue: #0048c0bf;
$border-blue: #03ffff;

.root {
  background: url('~@/assets/bg_ai.jpg');
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  overflow: scroll;
  font-size: 16px;
  &.ai-shelf-detection-container {
    padding-bottom: 20px;
  }
  #announce-img,
  #banner-img {
    border-radius: 25px;
  }
  #join-event {
    border-color: $border-blue;
    border-radius: 25px;
    background: $button-blue;
    bottom: 0;
    width: 212px;
    height: 50px;
    transform: translate(0, 5px);
  }
  .btn {
    &.btn-info {
      padding: 0;
      border-radius: 25px;
      background: $light-blue;
      border-color: $light-blue;
    }
    &:active {
      background: $light-blue !important;
      border-color: $light-blue !important;
    }
    .image {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      background: url('~@/assets/announce_trophy.png');
      background-size: cover;
      height: 120px;
      width: 100%;
    }
    .col {
      &:nth-child(2) {
        transform: translate(-20px, 0);
      }
    }
  }
}
</style>
